<template>
  <div class="mb-3">
    <label
        v-if="label"
        class="form-label">
      {{ label }}
    </label>

    <div class="container ms-0">
      <div class="row col-9">
        <div class="" v-for="(filter, index) in filters" :key="confInputKey + index">
          <BynderFilterField
              class="mt-3"
              :id="'bynder-filter-' + index"
              :filter=filter
              v-on:bynder-filter-save="saveFilter($event, index)"
              @bynder-filter-remove="removeFilter(index)"
              :error="this.getFilterErrorMessage(index)"
          />
        </div>
      </div>
    </div>
    <button @click="addFilter" class="m-3 btn btn-sm btn-primary">
      <i class="bi bi-plus" aria-hidden="true"/>
      {{ $t('form.bynder_filters.add_filter') }}
    </button>
  </div>
</template>

<script>

import BynderFilterField from "./BynderFilterField";

export default {
  name: "BynderFilterFields",
  components: {BynderFilterField},
  props: {
    id: String,
    label: String,
    modelValue: Array,
    errors: Object,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      confInputKey: Math.random(),
      filters: this.modelValue
    }
  },
  methods: {
    getFilterErrorMessage (index) {
      if (this.errors && this.errors[index]) {
        return this.errors[index];
      }
      return null;
    },
    addFilter (e) {
      if (!this.filters) {
        this.filters = [];
      }

      this.filters.push({
        field: "",
        operator: "NOT_EMPTY",
        value: "",
      });

      e.preventDefault();
    },
    saveFilter (filter, index) {
      this.filters[index] = filter;
      this.updateValue();
    },
    removeFilter (index) {
      this.filters.splice(index, 1);
      this.updateValue();
    },
    updateValue () {
      this.$emit(`update:modelValue`, this.filters);
      this.confInputKey = Math.random()
    }
  },
}
</script>
