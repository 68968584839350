<template>
  <div class="row">
    <div class="text-center text-black">
      <div class="align-items-center h-50 login-header">
        <img :src="getImage('platform-logo.png')" class="w-150">
        <h1 class="title">Connect Platform</h1>
        <span>By Dataggo ©</span>
      </div>

      <div class="m-auto">
        <form @submit.prevent="handleSubmit" class="col-lg-4 col-sm-8 m-auto">
          <PasswordField
              id="plainPassword"
              :label="$t('user.property.plainPassword')"
              v-model="plainPassword"
              readOnly
              :error="errors.plainPassword"
              :required="true"
          />
          <PasswordField
              id="toCheckPlainPassword"
              :label="$t('user.property.toCheckPlainPassword')"
              v-model="toCheckPlainPassword"
              readOnly
              :error="errors.toCheckPlainPassword"
              :required="true"
          />
          <div class="text-start text-secondary">
            <p>{{$t('security.password.constraint.title')}}:</p>
            <ul>
              <li>{{$t('security.password.constraint.length')}}</li>
              <li>{{$t('security.password.constraint.upper')}}</li>
              <li>{{$t('security.password.constraint.lower')}}</li>
              <li>{{$t('security.password.constraint.number')}}</li>
              <li>{{$t('security.password.constraint.symbol')}}</li>
            </ul>
          </div>
          <div class="d-grid gap-2 mb-2 mt-5">
            <button class="btn btn-primary" type="submit" :disabled="loading">
              {{ $t('common.save') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {useToast} from "vue-toastification";
import ResetPasswordService from "../../services/reset-password.service";
import PasswordField from "../../components/Form/PasswordField";
import appStyleMixin from "@/mixins/appStyleMixin";

export default {
  name: "ResetPassword",
  mixins: [appStyleMixin],
  components: {PasswordField},
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      loading: false,
      plainPassword: '',
      toCheckPlainPassword: '',
      errors: {}
    };
  },
  computed: {
    ...mapState({
      loggedIn: state => state.auth.status.loggedIn,
    }),
  },
  created () {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleSubmit () {
      this.loading = true;
      ResetPasswordService.resetPassword(this.plainPassword, this.toCheckPlainPassword, this.$route.params.token).then(
          () => {
            this.toast.success(this.$i18n.t('security.passwordUpdated'));
            setTimeout(() => this.$router.push("/"), 3000);
          },
          (error) => {
            let message = error.message;
            if (error.response.data.violations) {
              message = error.response.data.violations[0].message;
            } else if (error.response.data.detail) {
              message = error.response.data.detail
            }

            this.toast.error(this.$i18n.t(message));
            this.loading = false;
          }
      );
    },
  },
  beforeMount () {
    if (navigator.language) {
      this.$i18n.locale = navigator.language.split('-')[0];
    }
  }
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Fira+Code');

.title {
  font-family: 'Fira Code', serif;
  font-size: 3.5rem;
}

</style>
