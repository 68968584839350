<template>
  <table v-if="items.length > 0" class="table table-hover">
    <thead>
    <tr>
      <th scope="col">{{ $t('common.id') }}</th>
      <th scope="col">{{ $t('user.property.username') }}</th>
      <th v-if="isGranted('ROLE_ADMIN')" scope="col">{{ $t('user.property.company.name') }}</th>
      <th scope="col">{{ $t('common.property.createdAt') }}</th>
      <th scope="col">{{ $t('common.property.updatedAt') }}</th>
      <th scope="col">{{ $t('user.property.lastConnection') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in items" :key="item.id" @click="$router.push('/user/' + item.id)">
      <td>{{ item.id }}</td>
      <td>{{ item.username }}</td>
      <td v-if="isGranted('ROLE_ADMIN')">{{ item.company.name }}</td>
      <td>
        <DateFormatter v-if="item.createdAt" :value="item.createdAt" format="DD/MM/YYYY HH:mm:ss"/>
      </td>
      <td>
        <DateFormatter v-if="item.updatedAt" :value="item.updatedAt" format="DD/MM/YYYY HH:mm:ss"/>
      </td>
      <td>
        <DateFormatter v-if="item.lastConnectionDate" :value="item.lastConnectionDate" format="DD/MM/YYYY HH:mm:ss"/>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import DateFormatter from "@/components/Utils/DateFormatter";
import SecurityMixin from "@/mixins/securityMixin";

export default {
  name: "UserGrid",
  components: {DateFormatter},
  mixins: [SecurityMixin],
  props: {
    items: Array,
  },
}
</script>

<style scoped lang="scss">
button.disabled {
  opacity: 1 !important;
}

table {
  tr {
    cursor: pointer;
  }
}
</style>
