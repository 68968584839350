<template>
  <div class="form-floating" :class="error ? 'is-invalid' : ''">
    <select
        class="form-select"
        :id="id"
        :disabled="readOnly"
        :multiple="multiple"
    >
      <option v-for="option in selectedOptions" :key="option.id" :value="option.id" selected>
        {{ option.text }}
      </option>
    </select>

    <label :for="id">
      {{ label }}
    </label>
    <div v-if="error" class="invalid-feedback d-block">
      {{ error }}
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.min.css'
import authToken from "@/services/Auth/auth-token";
import authHeader from "@/services/Auth/auth-header";
import apiClient from "@/services/api-client";

export default {
  name: "AsyncSelectField",
  props: {
    id: String,
    label: String,
    modelValue: null,
    error: String,
    ajaxRoute: String,
    ajaxResultId: {
      type: String,
      default: 'id'
    },
    ajaxResultLabel: {
      type: String,
      default: 'code'
    },
    ajaxSearchProperty: String,
    ajaxDefaultQuery: {
      type: Object,
      default: function() {
        return {};
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'form.field.select.default_placeholder'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    onChange: Function
  },
  data() {
    return {
      isLoading: false,
      value: this.modelValue,
      settings: null,
      selectedOptions: []
    }
  },
  methods: {
    initSelect2() {
      const self = this;
      const jquerySelect = jQuery('#' + this.id);
      jquerySelect.select2({
        theme: 'bootstrap-5',
        dropdownParent: jquerySelect.parent(),
        allowClear: this.readOnly ? false : this.allowClear,
        placeholder: this.$i18n.t(this.placeholder),
        ajax: {
          beforeSend: async function (request) {
            request.setRequestHeader("Authorization", 'Bearer ' + authToken());
          },
          url: process.env.VUE_APP_API_BASE_URL + this.ajaxRoute,
          data: function (params) {
            let query = self.ajaxDefaultQuery;
            query[self.ajaxSearchProperty] = params.term;

            return query;
          },
          processResults: function (data) {
            const results = data['hydra:member'];
            const items = [];
            results.map(result => items.push({id: result[self.ajaxResultId], text: result[self.ajaxResultLabel]}));

            return {
              results: items
            };
          },
        }
      })
          .on('change', (e) => {
            let value = jQuery(e.target).select2('val');
            if (!Array.isArray(value) && Number(value) == value) {
              value = Number(value);
            }

            this.$emit(`update:modelValue`, value);

            if (this.onChange) {
              this.onChange(value);
            }
          });
    }
  },
  mounted() {
    this.initSelect2();
  },
  async beforeMount() {
    if (this.modelValue.length) {
      const params = {};
      params[this.ajaxResultId] = this.modelValue;
      let config = {
        headers: authHeader(),
        params: params
      }

      const results = await apiClient().get(this.ajaxRoute, config);
      results.data['hydra:member'].map(result => this.selectedOptions.push({id: result[this.ajaxResultId], text: result[this.ajaxResultLabel]}))

      this.initSelect2();
    }
  }
}
</script>

<style scoped>

</style>
