<template>
  <component
      :is="configurationFormName"
      v-model="jobInstance"
      :confInputKey="confInputKey"
      :errors="errors"
      @update:modelValue="emitUpdate"
  />
</template>

<script>
import JsonConfigurationForm from "../Configuration/JsonConfigurationForm";
import ImatagConfigurationForm from "./BynderImatag/ImatagConfigurationForm";
import ShopifyConfigurationForm from "./BynderShopify/ShopifyConfigurationForm";
import BynderAkeneoConfigurationForm from "./BynderAkeneo/BynderAkeneoConfigurationForm";
import DeleteBynderAkeneoConfigurationForm
  from "@/components/Job/JobInstance/Configuration/BynderAkeneo/DeleteBynderAkeneoConfigurationForm.vue";

export default {
  name: "ConfigurationForm",
  components: {
    JsonConfigurationForm,
    ImatagConfigurationForm,
    ShopifyConfigurationForm,
    BynderAkeneoConfigurationForm,
    DeleteBynderAkeneoConfigurationForm,
  },
  props: {
    modelValue: Object,
    errors: Object,
  },
  data () {
    return {
      jobInstance: this.modelValue,
      confInputKey: Math.random()
    }
  },
  methods: {
    refreshComponent() {
      this.confInputKey = Math.random()
    },
    emitUpdate(value) {
      this.$emit(`update:modelValue`, value);
    },
  },
  computed: {
    configurationFormName () {
      if(this.jobInstance.showJsonConfiguration === true) {
        return JsonConfigurationForm;
      }

      switch (this.modelValue.jobCode) {
        case "bynder_imatag_watermark":
          return ImatagConfigurationForm
        case "bynder_media_to_akeneo_asset_sync":
        case "bynder_media_to_akeneo_ref_entity_sync":
        case "akeneo_to_akeneo_asset_product_multibinding":
          return BynderAkeneoConfigurationForm
        case "bynder_to_akeneo_delete_assets":
          return DeleteBynderAkeneoConfigurationForm
        case "bynder_media_to_shopify_product_image_sync":
          return ShopifyConfigurationForm
        default:
          return JsonConfigurationForm;
      }
    },
  },
}
</script>
