<template>
  <div class="card">
    <div v-if="!editMode" class="media-url-summary">
      <div class="card-body" @click="enterEditMode">
        <h5 class="card-title">{{ $t('form.media_url.title') }}</h5>
        <p class="card-text">{{ $t('form.media_url.bynder_field.show', {bynder_field: bynderMetadata}) }}</p>
        <p class="card-text">{{ $t('form.media_url.akeneo_attribute.show', {akeneo_attribute: attribute}) }}</p>
        <div v-if="error" class="invalid-feedback d-inline-block">
          <span> {{ error }} </span>
        </div>
      </div>
    </div>
    <div v-if="editMode" class="card-body">
      <h5 class="card-title">{{ $t('form.media_url.title') }}</h5>
      <div>
        <TextField class="w-75" id="bynderField" :label="$t('form.media_url.bynder_field.title')"
                   v-model="bynderMetadata"/>
        <TextField class="w-75" id="akeneoAttribute" :label="$t('form.media_url.akeneo_attribute.title')"
                   v-model="attribute"/>
        <div v-if="error" class="invalid-feedback d-inline-block">
          <span> {{ error }} </span>
        </div>
        <div class="bynder-filter-buttons">
          <button @click="saveMediaUrl" class="btn-edit mt-3 ms-2 btn btn-sm btn-success float-end">
            <i class="bi bi-check" aria-hidden="true"/>
            {{ $t('common.close') }}
          </button>
          <button @click="removeMediaUrl" class="btn-edit mt-3 ms-2 btn btn-sm btn-danger float-end">
            <i class="bi bi-trash2" aria-hidden="true"/>
            {{ $t('common.remove') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TextField from "../../../../../Form/TextField";

export default {
  name: "MadiaUrlField",
  components: {
    TextField,
  },
  props: {
    id: String,
    mediaUrlData: Object,
    error: String,
  },
  data () {
    return {
      editMode: this.mediaUrlData.bynderMetadata === "",
      bynderMetadata: this.mediaUrlData.bynderMetadata,
      attribute: this.mediaUrlData.attribute,
    }
  },
  methods: {
    enterEditMode () {
      this.editMode = true;
    },
    saveMediaUrl (e) {
      e.preventDefault();
      this.$emit('media-url-save', {
        bynderMetadata: this.bynderMetadata,
        attribute: this.attribute,
      });
      this.editMode = false;
    },
    removeMediaUrl (e) {
      this.$emit('media-url-remove');
      e.preventDefault();
    }
  }
}
</script>

<style lang="scss">

.media-url-summary {
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
}

</style>

