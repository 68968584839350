<template>
  <Loader v-if="isLoaded"/>
  <div v-if="!isLoaded" class="container-fluid">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <form id="user-account-form" class="mt-3" @submit.prevent="onSubmit()">
      <HeaderTitle :title="title">
        <template v-slot:button-area>
          <button v-if="user.id && currentUser.id !== user.id" type="button" class="m-1 btn btn-danger" :disabled="isDeleting" data-bs-toggle="modal"
                  data-bs-target="#confirm-deletion">
            <i class="bi bi-trash2" aria-hidden="true"/>
            {{ $t('common.delete') }}
          </button>
          <ConfirmationModal v-if="isGranted('ROLE_CLIENT_ADMIN')" modal-id="confirm-deletion" title="Confirm deletion" :action="deleteUser">
            <template v-slot:content>
              {{ $t('common.please_confirm') }}
            </template>
          </ConfirmationModal>
          <button type="submit" class="btn btn-success" :disabled="isSubmitting">
            <i class="bi bi-check-lg"/>
            {{ $t('common.save') }}
          </button>
        </template>
      </HeaderTitle>
      <div class="container">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#general">
              {{ $t('user.form.tab.generalData') }}
            </a>
          </li>
          <li v-if="currentUser.id === user.id" class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#security">
              {{ $t('user.form.tab.security') }}
            </a>
          </li>
        </ul>
        <div class="tab-content mt-3">
          <div class="tab-pane container active" id="general">
            <TextField
                class="w-75"
                id="platform_username"
                :label="$t('user.property.username')"
                v-model="user.username"
                :readOnly="Boolean(user.id)"
                :error="errors.username"
            />
            <TextField
                class="w-75"
                id="email"
                :label="$t('user.property.email')"
                v-model="user.email"
                :readOnly="Boolean(user.id)"
                :error="errors.email"
            />
            <SelectField
                v-if="companyOptions.length > 0 && isGranted('ROLE_ADMIN')"
                id="company"
                :label="$t('job.jobInstance.property.company_name')"
                v-model="user.companyCode"
                :error="errors.company"
                :options="companyOptions"
                class="w-75 mb-3"
            />
            <SelectField
                v-if="rolesOptions.length > 0 && isGranted('ROLE_CLIENT_ADMIN')"
                id="roles"
                :label="$t('user.property.roles')"
                v-model="user.roles"
                :error="errors.roles"
                :options="rolesOptions"
                class="w-75 mb-3"
                :read-only="!isGranted('ROLE_CLIENT_ADMIN') || user.id === currentUser.id"
                multiple
            />
            <SelectField
                id="preferredLocale"
                :label="$t('user.property.preferredLanguage')"
                v-model="user.preferredLocale"
                :error="errors.preferredLocale"
                :options="localeOptions"
                class="w-75 mb-3"
            />
          </div>
          <div class="tab-pane container fade" id="security" v-if="currentUser.id === user.id">
            <PasswordField
                id="currentPlainPassword"
                class="w-75"
                :label="$t('user.property.currentPlainPassword')"
                v-model="user.currentPlainPassword"
                readOnly
                :error="errors.currentPlainPassword"
            />
            <PasswordField
                id="plainPassword"
                class="w-75"
                :label="$t('user.property.plainPassword')"
                v-model="user.plainPassword"
                readOnly
                :error="errors.plainPassword"
            />
            <PasswordField
                id="toCheckPlainPassword"
                class="w-75"
                :label="$t('user.property.toCheckPlainPassword')"
                v-model="user.toCheckPlainPassword"
                readOnly
                :error="errors.toCheckPlainPassword"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TextField from "../Form/TextField";
import Loader from "../../components/Page/Loader";
import UserService from "../../services/user.service";
import PasswordField from "../Form/PasswordField";
import {useToast} from "vue-toastification";
import SelectField from "../Form/SelectField";
import LocaleService from "../../services/locale.service";
import HeaderTitle from "../Page/Content/HeaderTitle";
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import {mapState} from "vuex";
import ConfirmationModal from "@/components/Utils/ConfirmationModal";
import CompanyService from "@/services/company.service";
import securityMixin from "@/mixins/securityMixin";

export default {
  name: "UserForm",
  components: {Breadcrumbs, HeaderTitle, SelectField, PasswordField, TextField, Loader, ConfirmationModal},
  mixins: [securityMixin],
  setup() {
    const toast = useToast();

    return {toast};
  },
  props: {
    userId: String,
    title: String,
    breadcrumbsItems: Object
  },
  data() {
    return {
      isLoaded: false,
      isSubmitting: false,
      isDeleting: false,
      user: {},
      errors: {},
      companyOptions: []
    }
  },
  computed: {
    localeOptions() {
      return LocaleService.getAvailableLocalesSelectOptions();
    },
    rolesOptions() {
      const baseRoles = [
        {id: 'ROLE_USER', text: this.$t('user.role.user')},
        {id: 'ROLE_CLIENT_ADMIN', text: this.$t('user.role.client_admin')}
      ];

      if (this.isGranted('ROLE_ADMIN')) {
        baseRoles.push({id: 'ROLE_ADMIN', text: this.$t('user.role.admin')});
      }

      return baseRoles;
    },
    ...mapState({
      currentUser: state => state.auth.user
    })
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;
      this.errors = {};
      await UserService.updateOrCreateUser(this.user)
          .then((response) => {
            this.toast.success(this.$i18n.t('common.successSubmission'));
            this.user = response.data;
            if (this.user.company) {
              this.user.companyCode = this.user.company.code;
            }
            if (this.currentUser.id === this.user.id) {
              this.$i18n.locale = this.user.preferredLocale;
              localStorage.setItem('locale', this.user.preferredLocale);
              location.reload();
            }
            if (!this.userId) {
              this.$router.push('/user/' + response.data.id);
            }
          })
          .catch((error) => {
            this.isSubmitting = false;
            if (error.response && error.response.data) {
              this.toast.error(this.$i18n.t(error.response.data['hydra:title'] ?? error.response.data['message']));
              for (const violation of error.response.data.violations) {
                this.errors[violation.propertyPath] = violation.message;
              }
            } else {
              this.toast.error(this.$i18n.t('common.errorOccurred'));
            }
          });
      this.isSubmitting = false;
    },
    async deleteUser() {
      this.isDeleting = true;
      await UserService.removeUser(this.user.id)
          .then(() => {
            this.toast.success(this.$i18n.t('user.alert.message.deletion_success'));
            this.$router.push('/users');
          })
          .catch((error) => {
            console.log(error);
            this.toast.error(this.$i18n.t('common.errorOccurred'));
          });
      this.isDeleting = false;
    }
  },
  async beforeMount() {
    this.isLoaded = true;
    await UserService.getUserFromId(this.currentUser.id)
        .then((response) => {
          this.user.companyCode = response.data.company.code;
        })
        .catch(error => console.log(error));

    if (this.userId) {
      await UserService.getUserFromId(this.userId)
          .then((response) => {
            this.user = response.data;
            if (this.user.company) {
              this.user.companyCode = response.data.company.code;
            }
          })
          // TODO Handle error better than this.
          .catch(error => console.log(error));
    }

    await CompanyService.getCompaniesList()
        .then((response) => {
          for (let company of response.data['hydra:member']) {
            this.companyOptions.push({
              id: company.code,
              text: company.name
            });
          }
        })
        // TODO Handle error better than this.
        .catch(error => console.log(error));

    this.isLoaded = false;
  }
}
</script>

<style scoped>

</style>
