<template>
  <div class="card">
    <div v-if="!editMode" class="bynder-filter-summary">
      <div class="card-body" @click="enterEditMode">
        <h5 class="card-title">{{ $t('form.bynder_filter.field.show', {field: field}) }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ $t('form.bynder_filter.operator.' + operator) }}</h6>
        <div v-if="Array.isArray(this.value)">
          <ul v-for="(valueItem, index) in this.value" :key="index">
            <li>{{ valueItem }}</li>
          </ul>
        </div>
        <p v-if="!Array.isArray(this.value)" class="card-text">{{ value }}</p>
        <div v-if="error" class="invalid-feedback d-inline-block">
          <span> {{ error }} </span>
        </div>
      </div>
    </div>
    <div v-if="editMode" class="card-body">
      <h5 class="card-title">{{ $t('form.bynder_filter.field.show', {field: field}) }}</h5>
      <div v-if="editMode">
        <TextField class="w-75" id="field" :label="$t('form.bynder_filter.field.title')" v-model="field"/>
        <SelectField
            class="w-75"
            :id="this.id + 'operator'"
            :label="$t('form.bynder_filter.field.title')"
            v-model="operator"
            @change="cleanFilterValue(operator)"
            :options="operators"
            :allowClear="false"
        />
        <TextField
            v-if="getOperatorType() === 'text'"
            class="mt-3 w-75"
            id="value"
            :label="$t('form.bynder_filter.value.title')" v-model="value"
        />
        <TagField
            v-if="getOperatorType() ===  'array'"
            :addOnKey="[13]"
            :placeholder="$t('form.bynder_filter.value.add_value')"
            class="mt-3 col-9"
            id="value"
            v-model="value"
        />
        <div class="bynder-filter-buttons">
          <button @click="saveFilter" class="btn-edit mt-3 ms-2 btn btn-sm btn-success float-end">
            <i class="bi bi-check" aria-hidden="true"/>
            {{ $t('common.close') }}
          </button>
          <button @click="removeFilter" class="btn-edit mt-3 ms-2 btn btn-sm btn-danger float-end">
            <i class="bi bi-trash2" aria-hidden="true"/>
            {{ $t('common.remove') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TextField from "../../../../Form/TextField";
import SelectField from "../../../../Form/SelectField";
import TagField from "../../../../Form/TagField";

export default {
  name: "BynderFilterField",
  components: {
    TagField,
    TextField,
    SelectField
  },
  props: {
    id: String,
    filter: Object,
    error: String,
  },
  data () {
    return {
      editMode: this.filter.field === "",
      field: this.filter.field,
      operator: this.filter.operator,
      value: this.filter.value,
      operators: [
        {id: 'EQUAL', type: "text", text: 'form.bynder_filter.operator.EQUAL'},
        {id: 'IN', type: "array", text: 'form.bynder_filter.operator.IN'},
        {id: 'NOT_IN', type: "array", text: 'form.bynder_filter.operator.NOT_IN'},
        {id: 'NOT_EMPTY', type: "none", text: 'form.bynder_filter.operator.NOT_EMPTY'},
        {id: 'EMPTY', type: "none", text: 'form.bynder_filter.operator.EMPTY'},
        {id: 'CONTAINS', type: "text", text: 'form.bynder_filter.operator.CONTAINS'},
        {id: 'DOES_NOT_CONTAIN', type: "text", text: 'form.bynder_filter.operator.DOES_NOT_CONTAIN'},
        {id: 'REGEX', type: "text", text: 'form.bynder_filter.operator.REGEX'},
      ]
    }
  },
  methods: {
    cleanFilterValue () {
      this.value = null;
    },
    getOperatorType () {
      const operatorObject = this.operators.find(operator => operator.id === this.operator);
      return operatorObject ? operatorObject.type : null;
    },
    enterEditMode () {
      this.editMode = true;
    },
    saveFilter () {
      this.$emit('bynder-filter-save', {
        field: this.field,
        operator: this.operator,
        value: this.value,
      });
      this.editMode = false;
    },
    removeFilter () {
      this.$emit('bynder-filter-remove');
    }
  }
}
</script>

<style lang="scss">

.bynder-filter-summary {
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
}

</style>

