<template>
  <div class="card">
    <div class="mapping-summary">
      <div class="card-body" @click="openModal">
        <h5 v-if="!isReferenceEntityMapping" class="card-title">
          {{ akeneo_asset_family }}
        </h5>
        <h5 v-if="isReferenceEntityMapping" class="card-title">
          {{ reference_entity }}
        </h5>
        <p v-if="isReferenceEntityMapping" class="card-text">
          {{ code_metadata }}
        </p>
        <p class="card-text">
          {{ $t('form.bynderAkeneo.mapping.conditions.count', {n: conditions.length}) }}
        </p>
        <p class="card-text">
          {{ $t('form.bynderAkeneo.mapping.media_urls.count', {n: media_urls?.length}) }}
        </p>
        <p class="card-text">
          {{ $t('form.bynderAkeneo.mapping.metadata.count', {n: metadata?.length}) }}
        </p>
        <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
          <span> {{ formattedMessages }} </span>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
         data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t('form.bynderAkeneo.mapping.card.label', {code: akeneo_asset_family}) }}
            </h5>
          </div>
          <div class="modal-body">
            <TextField
                v-if="!isReferenceEntityMapping"
                class="w-75"
                id="akeneoAssetFamily"
                :required=true
                :label="$t('form.bynderAkeneo.mapping.akeneo_asset_family')"
                :error="getErrorMessage('akeneo_asset_family')"
                v-model="akeneo_asset_family"
            />
            <TextField
                v-if="isReferenceEntityMapping"
                class="w-75"
                id="referenceEntity"
                :required=true
                :label="$t('form.bynderAkeneo.mapping.reference_entity')"
                :error="getErrorMessage('reference_entity')"
                v-model="reference_entity"
            />
            <TextField
                v-if="isReferenceEntityMapping"
                class="w-75"
                id="codeMetadata"
                :required=true
                :label="$t('form.bynderAkeneo.mapping.code_metadata')"
                :error="getErrorMessage('code_metadata')"
                v-model="code_metadata"
            />
            <ToggleField
                v-if="isReferenceEntityMapping"
                class="w-75"
                id="allowCreation"
                :label="$t('form.bynderAkeneo.mapping.allow_creation')"
                v-model="allow_creation"
                :error="getErrorMessage('allow_creation')"
            />
            <BynderFilterFields
                id="conditions"
                :label="$t('form.bynderAkeneo.mapping.conditions')"
                :errors="getErrorMessage('conditions')"
                v-model="conditions"
            />
            <MediaUrlFields
                id="mediaUrls"
                :label="$t('form.bynderAkeneo.mapping.media_urls')"
                :errors="getErrorMessage('media_urls')"
                v-model="media_urls"
            />
            <MetadataFields
                id="metadata"
                :label="$t('form.bynderAkeneo.mapping.metadata')"
                :errors="getErrorMessage('metadata')"
                v-model="metadata"
            />
          </div>
          <div class="modal-footer">
            <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
              <span> {{ formattedMessages }} </span>
            </div>
            <button @click="removeMapping" class="mt-3 btn btn-sm btn-danger">
              <i class="bi bi-trash2" aria-hidden="true"/>
              {{ $t('common.remove') }}
            </button>
            <button @click="saveMapping" class="mt-3 btn btn-sm btn-success">
              <i class="bi bi-check" aria-hidden="true"/>
              {{ $t('common.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap'
import TextField from "@/components/Form/TextField";
import ToggleField from "@/components/Form/ToggleField";
import BynderFilterFields from "@/components/Job/JobInstance/Configuration/Bynder/BynderFilterFields";
import MediaUrlFields from "@/components/Job/JobInstance/Configuration/BynderAkeneo/Mapping/MediaUrlFields";
import MetadataFields from "@/components/Job/JobInstance/Configuration/BynderAkeneo/Mapping/MetadataFields";

export default {
  name: "MappingField",
  components: {
    TextField,
    ToggleField,
    BynderFilterFields,
    MediaUrlFields,
    MetadataFields,
  },
  props: {
    id: String,
    mapping: Object,
    isReferenceEntityMapping: Boolean,
    errors: Object,
  },
  data () {
    return {
      modal: null,
      akeneo_asset_family: this.mapping.akeneo_asset_family ?? "",
      reference_entity: this.mapping.reference_entity ?? "",
      code_metadata: this.mapping.code_metadata ?? "",
      allow_creation: this.mapping.allow_creation ?? false,
      conditions: this.mapping.conditions,
      media_urls: this.mapping.media_urls,
      metadata: this.mapping.metadata,
      errorMessage: this.error,
      formErrors: {},
    }
  },
  computed: {
    formattedMessages () {
      let formattedMessages = "";
      if (this.errors && Object.keys(this.errors).length !== 0) {
        for (const key in this.errors) {
          const message = typeof this.errors[key] === 'string' ? this.errors[key] : 'invalid'
          formattedMessages += `${key}: ${message}\n`;
        }
      }

      if (Object.keys(this.formErrors).length !== 0) {
        for (const key in this.formErrors) {
          formattedMessages += `${key}: ${this.formErrors[key]}\n`;
        }
      }

      return formattedMessages;
    },
  },
  methods: {
    openModal () {
      this.modal.show();
    },
    saveMapping (e) {
      e.preventDefault();

      this.formErrors = {};
      if (this.akeneo_asset_family === "" && !this.isReferenceEntityMapping) {
        this.formErrors.akeneo_asset_family = this.$t('common.isRequired');

        return;
      }
      if (this.reference_entity === "" && this.isReferenceEntityMapping) {
        this.formErrors.reference_entity = this.$t('common.isRequired');

        return;
      }

      this.modal.hide();

      let saveData = {
        conditions: this.conditions,
        media_urls: this.mapping.media_urls,
        metadata: this.metadata,
      }

      if (this.isReferenceEntityMapping) {
        saveData.reference_entity = this.reference_entity;
        saveData.code_metadata    = this.code_metadata;
        saveData.allow_creation   = this.allow_creation;
      } else {
        saveData.akeneo_asset_family = this.akeneo_asset_family;
      }

      this.$emit('mapping-save', saveData);
    },
    removeMapping (e) {
      if (confirm(this.$t('form.bynderAkeneo.mapping.delete_confirmation'))) {
        this.modal.hide();
        this.$emit('mapping-remove');
      }

      e.preventDefault();
    },
    getErrorMessage (field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field];
      }
      if (this.formErrors && this.formErrors[field]) {
        return this.formErrors[field];
      }

      return null;
    },
  },
  mounted () {
    this.modal = new Modal(this.$refs.exampleModal)
    if (this.akeneo_asset_family === "" && this.reference_entity === "") {
      this.modal.show();
    }
  },
}
</script>

<style lang="scss">

.mapping-summary {
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
}

</style>
