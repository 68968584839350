<template>
  <div class="mb-3">
    <label
        v-if="label"
        class="form-label">
      {{ label }}
    </label>

    <div class="container ms-0">
      <div class="row col-9">
        <div class="" v-for="(metadataConf, index) in metadataConfs" :key="confInputKey + index">
          <MetadataField
              class="mt-3"
              :id="'metadata-conf-' + index"
              :metadataConf =metadataConf
              v-on:metadata-conf-save="saveMetadataConf($event, index)"
              @metadata-conf-remove="removeMetadataConf(index)"
              :error="this.getMetadataConfErrorMessage(index)"
          />
        </div>
      </div>
    </div>
    <button @click="addMetadataConf" class="m-3 btn btn-primary">
      <i class="bi bi-plus" aria-hidden="true"/>
      {{ $t('form.metadata_confs.add_conf') }}
    </button>
  </div>
</template>

<script>

import MetadataField from "@/components/Job/JobInstance/Configuration/BynderAkeneo/Mapping/MetadataField";

export default {
  name: "MetadataFields",
  components: {MetadataField},
  props: {
    id: String,
    label: String,
    modelValue: Array,
    errors: Object,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      confInputKey: Math.random(),
      metadataConfs: this.modelValue
    }
  },
  methods: {
    getMetadataConfErrorMessage(index) {
      if(this.errors && this.errors[index]) {
        return this.errors[index];
      }
      return null;
    },
    addMetadataConf (e) {
      if (!this.metadataConfs) {
        this.metadataConfs = [];
      }

      this.metadataConfs.push({
        bynderMetadata: "",
        property_type: "simple",
        attribute: "",
      });

      e.preventDefault();
    },
    saveMetadataConf (metadataConf, index) {
      this.metadataConfs[index] = metadataConf;
      this.updateValue();
    },
    removeMetadataConf (index) {
      this.metadataConfs.splice(index, 1);
      this.updateValue();
    },
    updateValue () {
      this.$emit(`update:modelValue`, this.metadataConfs);
      this.confInputKey = Math.random()
    }
  }
}
</script>
