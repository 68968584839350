<template>
  <div class="card">
    <div v-if="!editMode" class="metadada-summary">
      <div class="card-body" @click="enterEditMode">
        <h5 class="card-title">{{ this.formatBynderMetadata(bynderMetadata) + " -> " + attribute }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ type }}</h6>
        <div v-if="error" class="invalid-feedback d-inline-block">
          <span> {{ error }} </span>
        </div>
      </div>
    </div>
    <div v-if="editMode" class="card-body">
      <div v-if="editMode">
        <SelectField
            class="mb-3"
            :id="this.id + 'type'"
            :label="$t('form.metadata_conf.type.title')"
            v-model="type"
            @change="cleanMetadataConf(type)"
            :options="types"
            :allowClear="false"
        />
        <TextField
            v-if="type ==='video_preview'"
            id="fieldSeparator"
            :label="$t('form.metadata_conf.video_preview.extension')"
            v-model="extension"
        />
        <TextField
            v-if="type ==='pattern'"
            id="fieldSeparator"
            :label="$t('form.metadata_conf.pattern.field_separator')"
            v-model="fieldSeparator"
        />
        <TextField
            v-if="type ==='pattern'"
            id="targetSeparator"
            :label="$t('form.metadata_conf.pattern.target_separator')"
            v-model="targetSeparator"
        />
        <TextField
            :required=true
            id="attribute"
            :label="bynderMetadataLabel"
            :tooltip="type ==='pattern' ? $t('form.metadata_conf.pattern.tooltip') : ''"
            v-model="bynderMetadata"
        />
        <TextField
            v-if="type ==='extract_regex'"
            id="fieldSeparator"
            :label="$t('form.metadata_conf.extract_regex.regex')"
            v-model="regex"
        />
        <TextField
            v-if="type ==='extract_regex'"
            id="fieldSeparator"
            :label="$t('form.metadata_conf.extract_regex.pattern')"
            v-model="regexPattern"
        />
        <TextField
            :required=true
            id="attribute"
            :label="$t('form.metadata_conf.attribute.title')"
            v-model="attribute"
        />
        <div class="mt-3 row col-12">
          <LocaleAndScopeField
              v-model="locale"
              ref="localeForm"
              :placeholder="$t('form.metadata_conf.locale.add_locale')"
              id="locales"
          />
          <LocaleAndScopeField
              v-model="channel"
              :placeholder="$t('form.metadata_conf.locale.add_channel')"
              id="scope"
          />
        </div>
        <div v-if="error" class="invalid-feedback d-inline-block">
          <span> {{ error }} </span>
        </div>
        <div class="metadata-conf-buttons">
          <button @click="saveMetadataConf" class="btn-edit mt-3 ms-2 btn btn-sm btn-success float-end">
            <i class="bi bi-check" aria-hidden="true"/>
            {{ $t('common.close') }}
          </button>
          <button @click="removeMetadataConf" class="btn-edit mt-3 ms-2 btn btn-sm btn-danger float-end">
            <i class="bi bi-trash2" aria-hidden="true"/>
            {{ $t('common.remove') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TextField from "@/components/Form/TextField";
import SelectField from "@/components/Form/SelectField";
import LocaleAndScopeField from "@/components/Job/JobInstance/Configuration/BynderAkeneo/Mapping/LocaleAndScopeField";

export default {
  name: "MetadataField",
  components: {
    TextField,
    SelectField,
    LocaleAndScopeField
  },
  props: {
    id: String,
    metadataConf: Object,
    error: String,
  },
  data () {
    return {
      editMode: this.metadataConf.bynderMetadata === "" || this.metadataConf.attribute === "",
      bynderMetadata: this.metadataConf.bynderMetadata ?? "",
      type: this.metadataConf.property_type ?? "simple",
      attribute: this.metadataConf.attribute,
      extension: this.metadataConf.extension ?? null,
      regex: this.metadataConf.regex ?? null,
      regexPattern: this.metadataConf.regexPattern ?? null,
      targetSeparator: this.metadataConf.target_separator ?? null,
      fieldSeparator: this.metadataConf.field_separator ?? null,
      locale: this.metadataConf.locale ?? null,
      channel: this.metadataConf.channel ?? null,
      types: [
        {id: 'simple', text: 'form.metadata_conf.type.simple'},
        {id: 'fixed_value', text: 'form.metadata_conf.type.fixed_value'},
        {id: 'video_preview', text: 'form.metadata_conf.type.video_preview'},
        {id: 'pattern', text: 'form.metadata_conf.type.pattern'},
        {id: 'extract_regex', text: 'form.metadata_conf.type.extract_regex'},
        {id: 'external_reference', text: 'form.metadata_conf.type.external_reference'},
        {id: 'product_id', text: 'form.metadata_conf.type.product_id'},
      ]
    }
  },
  computed: {
    bynderMetadataLabel () {
      switch (this.type) {
        case 'pattern':
          return this.$t('form.metadata_conf.pattern.field');
        case 'simple':
          return this.$t('form.metadata_conf.bynderMetadata.title');
        case 'fixed_value':
          return this.$t('form.metadata_conf.fixed_value.title');
      }
      return '';
    }
  },
  methods: {
    formatBynderMetadata (bynderMetadata) {
      return bynderMetadata.replace(/%%/g, '');
    },
    cleanMetadataConf (selectedType) {
      this.extension    = null;
      this.regex        = null;
      this.regexPattern = null;
      if (selectedType === "pattern") {
        this.fieldSeparator  = ",";
        this.targetSeparator = ",";
      } else {
        this.fieldSeparator  = null;
        this.targetSeparator = null;
      }
    },
    enterEditMode () {
      this.editMode = true;
    },
    saveMetadataConf (e) {
      let conf = {
        bynderMetadata: this.bynderMetadata,
        attribute: this.attribute,
        property_type: this.type
      };

      if (this.locale != null) {
        conf.locale = this.locale;
      }
      if (this.channel != null) {
        conf.channel = this.channel;
      }
      if (this.extension != null) {
        conf.extension = this.extension;
      }
      if (this.regex != null) {
        conf.regex = this.regex;
      }
      if (this.regexPattern != null) {
        conf.regexPattern = this.regexPattern;
      }
      if (this.fieldSeparator != null) {
        conf.fieldSeparator = this.fieldSeparator;
      }
      if (this.targetSeparator != null) {
        conf.targetSeparator = this.targetSeparator;
      }

      this.$emit('metadata-conf-save', conf);
      this.editMode = false;

      e.preventDefault();
    },
    removeMetadataConf (e) {
      this.$emit('metadata-conf-remove');
      e.preventDefault();
    }
  }
}
</script>

<style lang="scss">

.metadada-summary {
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
}

</style>

